.login__form-container {
	background-color: #fff;
	padding-left: 5%;
	padding-top: 5%;
	padding-right: 5%;
	max-width: 22vw;
}

.login__component {
	margin-top: 30%;
}

.login__tag-line {
	font-size: 34px;
	margin-top: 5%;
	color: #164172;
	font-weight: 300;
}

.login__background-img {
	height: 100vh;
}

.login__alwarqa-logo {
	position: absolute;
	height: 8%;
	right: 20px;
	top: 20px;
}

.login__page-image {
	box-shadow: 10px 1px 5px 10px #cecece;
	height: 100vh;
}
