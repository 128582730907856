.projects__back-button {
	color: #231F20;
}

.projects__back-button {
	cursor: pointer;
}

.projects__admin-container {
	margin-left: 5vw;
	margin-top: 2vh;
	margin-bottom: 50px;
}

.projects__table-container {
	margin-right: 3vw;
}

.projects__text-under-on-hover:hover {
	cursor: pointer;
	text-decoration: underline;
}

.projects__directory-container {
	display: flex;
	margin-top: 20px;
	gap: 20px;
}

.projects__project-card-container {
	margin-top: 20px;
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
}

.projects__project-card-container > * {
	flex-grow: 0;
	flex-shrink: 0;
}
