.organizations__root {
	margin-left: 5vw;
	margin-top: 2vh;
}

.organizations__container {
	margin-top: 20px;
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
}

.organizations__container > * {
	width: 250px;
	flex-grow: 0;
	flex-shrink: 0;
}
