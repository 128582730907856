.add-project__admin-container {
	padding-left: 5vw;
	margin-bottom: 60px;
	margin-top: 2vh;
}

.add-project__container {
	margin-top: 5vh;
}

.add-project__section {
	margin: 20px 0px;
}

.add-project__upload-container {
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
}
