.sidebar-directory__project-list-item {
	width: 100%;
	color: #ccc;
	font-size: 16px;
}

.sidebar-directory__directory-name-container:hover {
	background-color: #ffffff14;
	cursor: pointer;
}

.sidebar-directory__folder-icon-side-bar,
.sidebar-directory__side-bar-video-icon {
	color: #ccc;
	margin-right: 6px;
	font-size: 22px !important;
}

.sidebar-directory__directory {
	padding-bottom: 10px;
	padding: 0px 10px;
}

.sidebar-directory__directory-children {
	padding: 0px 10px;
}

.sidebar-directory__directory-name-container {
	padding: 4px 10px;
	border-radius: 6px;
}
