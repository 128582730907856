.project-card__root {
	padding: 10px;
	display: flex;
	margin-top: 20px;
	width: 350px;
}

.project-card__name {
	font-size: 18px;
	margin: 8px 0px;
}
