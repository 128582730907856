.custom-map__custom-map {
	height: 100%;
	border-radius: 5px;
}

.custom-map__container {
	height: 85vh;
	width: 30vw;
	background-color: #ededed;
	border-radius: 10px;
}

.custom-map__upload-map-component {
	height: 85vh;
	width: 100%;
}

.custom-map__blurred {
	filter: blur(2px);
}

.custom-map__selected-marker {
	z-index: 1000 !important;
}

.custom-map__non-selected-marker {
	z-index: 999 !important;
}
