.sidebar__side-bar {
	background-color: #164172;
	min-width: 400px;
	height: 100%;
}

.sidebar__closed-side-bar {
	width: 0.4vw;
	background-color: #164172;
	height: 100vh;
}

.sidebar__menu-icon {
	margin-top: 8vh !important;
	color: #231f20 !important;
	background-color: #164172 !important;
	border-radius: 0px 20px 20px 0px !important;
}

.sidebar__menu-icon:hover {
	background-color: #164172;
}

.sidebar__account-icon {
	color: white;
	font-size: 8vw !important;
}

.sidebar__logout-button,
.sidebar__admin-button {
	border-radius: 25px;
	font-size: 14px;
	padding: 8px 40px;
	background-color: #231f20 !important;
	vertical-align: middle;
	font-family: 'Open Sans', sans-serif;
	color: white !important;
}

.sidebar__logout-button:hover,
.sidebar__admin-button:hover {
	background-color: #4d4d4d !important;
	cursor: pointer;
}

.sidebar__divider {
	background-color: #ccc;
}

.sidebar__header {
	font-weight: 700;
	font-size: 12px;
	color: #ccc;
	padding: 0px 20px;
}

.sidebar__my-projects-container {
	min-height: 70vh;
}

.sidebar__project-list {
	list-style: none;
	padding-left: 0;
}
