.organization-card__root {
	flex: 1 1 auto;
	max-width: 250px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.organization-card__media {
	height: 100px;
}

.orgniation-card__name {
	font-size: 16px !important;
}
