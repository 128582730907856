.video__video-container {
	height: 85vh;
	width: 63%;
	background-color: #ededed;
	border-radius: 10px;
	margin-left: 2%;
}

.video__current-time {
	margin-left: 5px;
}

.video__button-container {
	position: absolute;
	margin-top: 40vh;
	margin-left: -36vw;
	font-style: italic;
	font-size: 24px;
	color: #fff;
	text-shadow: 5px 5px 5px 5px #fff;
}

.video__time {
	color: grey;
	display: inline;
}

.video__grey {
	color: grey;
}

.video__video-background {
	height: 100%;
	width: 100%;
	filter: blur(3px);
}

.video__slash {
	margin-left: 3px;
	margin-right: 3px;
}

.video__video-controls-container {
	margin-top: 6px;
	margin-left: 4px;
}
