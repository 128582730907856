.directories__back-button {
	color: #231F20;
}

.directories__back-button {
	cursor: pointer;
}

.directories__admin-container {
	margin-left: 5vw;
	margin-top: 2vh;
}

.directories__table-container {
	padding-right: 3vw;
	padding-bottom: 60px;
}

.directories__text-under-on-hover:hover {
	cursor: pointer;
	text-decoration: underline;
}

.directories__directory-container {
	display: flex;
	margin-top: 20px;
	gap: 20px;
	flex-wrap: wrap;
}
