.directory-card {
	display: flex;
	align-items: center;
	padding: 6px 10px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.2), 0 1px 3px 1px rgba(60, 64, 67, 0.1);
	cursor: pointer;
	color: #231F20;
}

.directory-card:hover {
	box-shadow: 0 2px 2px rgba(60, 64, 67, 0.2), 0 2px 4px rgba(60, 64, 67, 0.1);
	background-color: rgba(51, 51, 51, 0.2);
}

.directory-card__icon {
	margin-right: 8px;
	color: #231F20;
}

.directory-card__name {
	flex-grow: 1;
	font-size: 16px;
	margin-bottom: 1px;
}

.directory-card__menu {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 8px;
}

.MuiIconButton-root {
	padding: 8px;
}
